import './app.scss';
import 'lazysizes';
// import 'intersection-observer';

// if you need react, just enable it by commenting in this line
// import react from './react/index';

(function () {
    // if you need react, just enable it by commenting in this line
    // react.init();

    const accordionSections = document.querySelectorAll('.accordion-section') as NodeListOf<HTMLElement>;
    if (accordionSections.length) {
        import('./ts/accordion').then((faq) => {
            accordionSections.forEach(function (section) {
                faq.default.init(section);
            });
        });
    }

    // const sectionsBackground = document.querySelectorAll('.headline-video-loop');
    // if (sectionsBackground.length) {
    //     import('./ts/background-color').then((section) => {
    //         bgColor.default.init(section);
    //     });
    // }

    const sectionsBackground = document.querySelectorAll('.headline-video-loop') as NodeListOf<HTMLElement>;
    if (sectionsBackground.length) {
        import('./ts/background-color').then((bgColorSection) => {
            sectionsBackground.forEach((section) => {
                bgColorSection.default.init(section);
            });
        });
    }

    const featureSections = document.querySelectorAll('.feature-section') as NodeListOf<HTMLElement>;
    if (featureSections.length) {
        import('./ts/feature-accordion').then((featureSection) => {
            featureSections.forEach(function (section) {
                featureSection.default.init(section);
            });
        });
    }

    const featureImageAccordions = document.querySelectorAll('.feature-image-accordion') as NodeListOf<HTMLElement>;
    if (featureImageAccordions.length) {
        import('./ts/feature-accordion').then((featureImageAccordion) => {
            featureImageAccordions.forEach(function (section) {
                featureImageAccordion.default.init(section);
            });
        });
    }

    const menu = document.getElementById('header-menu');
    if (menu) {
        import('./ts/header').then((header) => {
            header.default.init();
        });
    }

    const aosElements = document.querySelectorAll('[data-aos]');
    if (aosElements.length) {
        import('./ts/aos').then((aos) => {
            aos.default.init();
        });
    }

    const masonryGridSections = document.querySelectorAll('.masonry-grid-section') as NodeListOf<HTMLElement>;
    if (masonryGridSections.length) {
        import('./ts/masonry-grid').then((grid) => {
            masonryGridSections.forEach((container) => {
                grid.default.init(container);
            });
        });
    }

    // Init all swiper if there are some
    const allSwiper = document.querySelectorAll('.swiper-container') as NodeListOf<HTMLElement>;
    if (allSwiper.length) {
        import('./ts/swiper').then((swiper) => {
            allSwiper.forEach(function (swiperElement) {
                if (!swiperElement.dataset.swiperOptions) return;
                // Set swiper element and swiper options from data-attribute
                swiper.default.init(swiperElement, JSON.parse(swiperElement.dataset.swiperOptions));
            });
        });
    }

    const forms = document.querySelectorAll('div[data-form-id]');
    if (forms.length) {
        import('./ts/form').then((form) => {
            form.default.init();
        });
    }

    // no element request because element will be generated via js, only used once
    import('./ts/cookie-banner').then((cookieBanner) => {
        cookieBanner.default.init();
    });

    const youtubeVideoContainers = document.querySelectorAll('.youtube-video-container') as NodeListOf<HTMLElement>;
    if (youtubeVideoContainers.length) {
        import('./ts/youtube-video').then((youtubeVideo) => {
            youtubeVideoContainers.forEach((container) => {
                youtubeVideo.default.init(container);
            });
        });
    }

    const faqPage = document.querySelector('.faq-page') as HTMLElement;
    if (faqPage) {
        import('./ts/faq-accordion').then((faqAccordion) => {
            faqAccordion.default.init(faqPage);
        });
    }
})();
